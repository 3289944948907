<template>
  <div @click="reset_background_color">
    <div class="white--text">
      Votre <b>url</b> pour les clients :
      <a :href="'/suggestion/' + $store.state.bar_nom"
        ><i
          >https://jukes-bar.com/suggestion/{{ this.$store.state.bar_nom }}</i
        ></a
      >
    </div>
    <h1 class="white--text">Liste des suggestions</h1>
    <v-card elevation="10" max-width="800" class="mx-auto mt-6">
      <v-virtual-scroll
        :bench="benched"
        :items="suggestions"
        height="600"
        item-height="64"
      >
        <template v-slot:default="{ item }">
          <v-list-item
            :key="item.id"
            :style="item.background ? 'background-color:#d0cccc;' : ''"
          >
            <v-list-item-content>
              <v-list-item-title class="retour_chariot">
                <!-- mettre class="text-wrap" pour faire un retour chariot -->
                <strong>
                  {{ item.musique }}
                </strong>
              </v-list-item-title>
            </v-list-item-content>

            <v-list-item-action>
              <div class="d-flex justify-space-between">
                <div>
                  <v-btn
                    icon
                    :href="
                      'https://www.youtube.com/results?search_query=' +
                      item.musique
                    "
                    target="_blank"
                    ><v-icon large color="red"> mdi-youtube </v-icon></v-btn
                  >
                </div>
                <div>
                  <v-btn
                    icon
                    :href="url_for_spotify(item.musique)"
                    target="_blank"
                    ><v-icon large color="green"> mdi-spotify </v-icon></v-btn
                  >
                </div>
                <div>
                  <v-btn
                    icon
                    :href="url_for_deezer(item.musique)"
                    target="_blank"
                    ><img src="./img/deezer_round.png" class="deezer"
                  /></v-btn>
                </div>
              </div>
            </v-list-item-action>
          </v-list-item>

          <v-divider></v-divider>
        </template>
      </v-virtual-scroll>
    </v-card>
    <v-snackbar v-model="snackbar">
      {{ text_snackbar }}

      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
          Fermer
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
const axios = require("axios");

export default {
  name: "ListeSuggestion",
  data() {
    return {
      benched: 0,
      text_snackbar: "",
      snackbar: false,
      suggestions: [],
    };
  },
  sockets: {
    suggestion_socket: function (data) {
      if (this.$store.state.bar_nom == data.bar_nom) {
        data.background = true;
        console.log(data);
        this.suggestions.unshift(data);

        //gestion de la notification
        this.showNotification();
      } else {
        null;
      }
    },
  },
  methods: {
    showNotification() {
      if ("serviceWorker" in navigator) {
        navigator.serviceWorker.ready // returns a Promise, the active SW registration
          .then((swreg) => {
            swreg.showNotification("Nouvelle suggestion !", {
              body: this.suggestions[0].musique,
              icon: "/favicon.png",
              image: "/favicon.png",
              vibrate: [200, 100, 200, 100, 200, 100, 200],
              badge: null,
              actions: [{ action: "notificationclick", title: "Voir" }],
              data: {
                dateOfArrival: Date.now(),
                primaryKey: 1,
              },
            });
          });
      }
      if (
        Notification.permission == "denied" ||
        Notification.permission == "default"
      ) {
        Notification.requestPermission(function (permission) {
          // Quelque soit la réponse de l'utilisateur, nous nous assurons de stocker cette information
          if (!("permission" in Notification)) {
            Notification.permission = permission;
          }
          // Si l'utilisateur est OK, on crée une notification
          // if (permission === "granted") {
          //   if ("serviceWorker" in navigator) {
          //     navigator.serviceWorker.ready // returns a Promise, the active SW registration
          //       .then((swreg) => {
          //         swreg.showNotification("Nouvelle suggestion !", {
          //           body: this.suggestions[0].musique,
          //           icon: "/favicon.png",
          //           image: "/favicon.png",
          //           vibrate: [300, 200, 300],
          //           badge: null,
          //         });
          //       });
          //   }
          // }
        });
      }
    },
    url_for_spotify(item) {
      return "https://open.spotify.com/search/" + item.replace(" ", "%20");
    },
    url_for_deezer(item) {
      return "deezer://www.deezer.com/search/" + item.replace(" ", "%20");
    },
    get_last_suggestion() {
      axios({
        method: "post",
        url: this.$store.state.url + "/get_suggestion",
        data: {
          bar_nom: this.$store.state.bar_nom,
        },
      })
        .then((response) => {
          if (response.data.code == 200) {
            this.suggestions = response.data.musique;
          } else {
            this.text_snackbar = "Erreur d'envoie";
            this.snackbar = true;
          }
        })
        .catch(function (error) {
          this.text_snackbar = "Erreur d'envoie";
          this.snackbar = true;
          console.log(error);
        });
    },
    reset_background_color() {
      this.suggestions.forEach((element) => {
        element.background = false;
      });
    },
  },
  created() {
    this.get_last_suggestion();
  },
};
</script>
<style scoped>
.deezer {
  width: 2em;
}
.v-list-item__content {
  overflow: visible !important;
}

@media only screen and (max-width: 600px) {
  .retour_chariot {
    max-width: 200px;
  }
}
</style>
